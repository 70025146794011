<template>
	<div class="formbody" v-loading="loadings">
		<el-form  label-width="100px" :model="examineruleForm" :rules="examinerules"
			ref="examineruleForm" class="">
			<el-form-item label="厂家名称" prop="ftyName">
				<el-input @input="inputchange($event)" v-model="examineruleForm.ftyName"></el-input>
			</el-form-item>
			<el-form-item label="厂址" prop="ftyAddress">
				<el-input @input="inputchange($event)" v-model="examineruleForm.ftyAddress"></el-input>
			</el-form-item>
			<el-form-item label="电话" prop="ftyTel">
				<el-input @input="inputchange($event)" v-model="examineruleForm.ftyTel"></el-input>
			</el-form-item>
			<el-form-item label="法人姓名" prop="ftyLegalUser">
				<el-input @input="inputchange($event)" v-model="examineruleForm.ftyLegalUser"></el-input>
			</el-form-item>
			<el-form-item label="联系电话">
				<el-input oninput="this.value=this.value.replace(/[^\d\,]/g,'')" @input="inputchange($event)"
					v-model="examineruleForm.ftyFiled4"></el-input>
			</el-form-item>
			<el-form-item label="产品类型">
				<el-input @input="inputchange($event)" v-model="examineruleForm.ftyFiled1"></el-input>
			</el-form-item>
			<el-form-item label="营业范围">
				<el-input @input="inputchange($event)" type="textarea" v-model="examineruleForm.ftyBusiness"></el-input>
			</el-form-item>
			<el-form-item label="公司简介">
				<el-input @input="inputchange($event)" type="textarea" v-model="examineruleForm.ftyContent"></el-input>
			</el-form-item>
			<el-form-item label="公司官网地址">
				<el-input @input="inputchange($event)" v-model="examineruleForm.ftyUrl"></el-input>
			</el-form-item>
			<el-form-item label="营业执照">
				<el-upload accept="image/jpeg,image/png" action="#" :auto-upload="false" :file-list="ftyCertificateList"
					list-type="picture-card" :before-upload="beforeUpload" :on-change="ftyCertificateonChange"
					:on-remove="ftyCertificateremove" :on-preview="ftyCertificatePreview">
					<i class="el-icon-plus"></i>
				</el-upload>
				<el-dialog :visible.sync="ftyCertificatedialogVisible">
					<img width="100%" :src="ftyCertificatedialogImageUrl" alt="">
				</el-dialog>
			</el-form-item>
			<el-form-item label="合格证书">
				<el-upload accept="image/jpeg,image/png" action="#" :auto-upload="false" :file-list="ftyQualifiedList"
					list-type="picture-card" :before-upload="beforeUpload" :on-change="ftyQualifiedonChange"
					:on-remove="ftyQualifiedremove" :on-preview="ftyQualifiedPreview">
					<i class="el-icon-plus"></i>
				</el-upload>
				<el-dialog :visible.sync="ftyQualifieddialogVisible">
					<img width="100%" :src="ftyQualifieddialogImageUrl" alt="">
				</el-dialog>
			</el-form-item>
			<el-form-item label="公司logo">
				<el-upload accept="image/jpeg,image/png" action="#" :auto-upload="false" :file-list="ftyLogoList"
					list-type="picture-card" :before-upload="beforeUpload" :on-change="ftyLogoonChange"
					:on-remove="ftyLogoremove" :on-preview="ftyLogoPreview">
					<i class="el-icon-plus"></i>
				</el-upload>
				<el-dialog :visible.sync="ftyLogodialogVisible">
					<img width="100%" :src="ftyLogodialogImageUrl" alt="">
				</el-dialog>
			</el-form-item>
		</el-form>
		<div class="buttons">
			<el-button @click="$router.go(-1)">取 消</el-button>
			<el-button type="primary" @click="submit()">确 定</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loadings: false,
				userId: sessionStorage.getItem('userId'),
				ftyCertificatemodify: [],
				ftyQualifiedmodify: [],
				ftyLogomodify: [],
				ftyCertificateList: [],
				ftyQualifiedList: [],
				ftyLogoList: [],
				ftyCertificate: [],
				ftyQualified: [],
				ftyLogo: [],
				newsftyCertificateList: [],
				newsftyQualifiedList: [],
				newsftyLogoList: [],
				ftyCertificatedialogVisible: false,
				ftyQualifieddialogVisible: false,
				ftyLogodialogVisible: false,
				ftyCertificatedialogImageUrl: '',
				ftyQualifieddialogImageUrl: '',
				ftyLogodialogImageUrl: '',
				examineruleForm: {
					ftyName: '',
					ftyLegalUser: "",
					ftyTel: '',
					ftyAddress: '',
					ftyBusiness: '',
					ftyContent: '',
					ftyUrl: '',
				},
				examinerules: {
					ftyName: [{
						required: true,
						message: '请输入厂家名称',
						trigger: 'blur'
					}],
					ftyLegalUser: [{
						required: true,
						message: '请输入厂家法人姓名',
						trigger: 'blur'
					}],
					ftyTel: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						message: "请输入合法手机号/电话号",
						trigger: "blur"
					}],
					ftyAddress: [{
						required: true,
						message: '请输入厂家地址',
						trigger: 'blur'
					}]
				},
			}
		},
		created() {
			// this.actionurl = this.$commonFun.serverSrc + "programme/uploadFile"
			if (this.$route.query.id) {
				this.getmanufactor()
			}
		},
		mounted() {},
		methods: {
			inputchange() {
				this.$forceUpdate();
			},
			//根据id获取厂家详情
			getmanufactor() {
				this.$post('programme/selectByPrimaryKey', {
					id: this.$route.query.id
				}).then((res) => {
					console.log(res)
					this.examineruleForm.ftyName = res.ftyName
					this.examineruleForm.ftyLegalUser = res.ftyLegalUser
					this.examineruleForm.ftyTel = res.ftyTel
					this.examineruleForm.ftyAddress = res.ftyAddress
					this.examineruleForm.ftyBusiness = res.ftyBusiness
					this.examineruleForm.ftyContent = res.ftyContent
					this.examineruleForm.ftyUrl = res.ftyUrl
					this.examineruleForm.ftyFiled1 = res.ftyFiled1
					this.examineruleForm.ftyFiled4 = res.ftyFiled4
					if (res.ftyCertificate) {
						res.ftyCertificate.split(',').forEach((e, m) => {
							this.ftyCertificateList.push({
								url: e
							})
						})
					}
					if (res.ftyQualified) {
						res.ftyQualified.split(',').forEach((e, m) => {
							this.ftyQualifiedList.push({
								url: e
							})
						})
					}
					if (res.ftyLogo) {
						res.ftyLogo.split(',').forEach((e, m) => {
							this.ftyLogoList.push({
								url: e
							})
						})
					}
				})
			},
			//图片选取后（同下）
			ftyCertificateonChange(file) {
				var _this = this;
				var event = event || window.event;
				var file = event.target.files[0];
				this.loadings = true
				this.bcebosupload(file, 0)
			},
			//图片删除时（同下）
			ftyCertificateremove(file, fileList) {
				let gh = []
				console.log(file)
				console.log(fileList)
				if (fileList && fileList.length > 0) {
					fileList.forEach((e, m) => {
						if (e.name) {
							this.ftyCertificate.forEach((q, t) => {
								if (q.name == e.name) {
									gh.push(q.url)
								}
							})
						} else {
							gh.push(e.url)
						}
					})
				} else {
					this.ftyCertificate = []
					this.ftyCertificateList = []
				}
				this.$nextTick(() => {
					console.log(gh)
					console.log(this.ftyCertificate)
					this.ftyCertificatemodify = gh
				})

			},
			//图片预览时（同下）
			ftyCertificatePreview(file) {
				this.ftyCertificatedialogImageUrl = file.url;
				this.ftyCertificatedialogVisible = true;
			},
			ftyQualifiedonChange(file) {
				var _this = this;
				var event = event || window.event;
				var file = event.target.files[0];
				this.loadings = true
				this.bcebosupload(file, 1)
			},
			ftyQualifiedremove(file, fileList) {
				let gh = []
				console.log(file)
				console.log(fileList)
				if (fileList && fileList.length > 0) {
					fileList.forEach((e, m) => {
						if (e.name) {
							this.ftyQualified.forEach((q, t) => {
								if (q.name == e.name) {
									gh.push(q.url)
								}
							})
						} else {
							gh.push(e.url)
						}
					})
				} else {
					this.ftyQualified = []
					this.ftyQualifiedList = []
				}
				this.$nextTick(() => {
					console.log(gh)
					this.ftyQualifiedmodify = gh
				})
			},
			ftyQualifiedPreview(file) {
				this.ftyQualifieddialogImageUrl = file.url;
				this.ftyQualifieddialogVisible = true;
			},
			ftyLogoonChange(file) {
				var _this = this;
				var event = event || window.event;
				var file = event.target.files[0];
				this.loadings = true
				this.bcebosupload(file, 2)
			},
			ftyLogoremove(file, fileList) {
				let gh = []
				// console.log(file)
				// console.log(fileList)
				if (fileList && fileList.length > 0) {
					fileList.forEach((e, m) => {
						if (e.name) {
							this.ftyLogo.forEach((q, t) => {
								if (q.name == e.name) {
									gh.push(q.url)
								}
							})
						} else {
							gh.push(e.url)
						}
					})
				} else {
					this.ftyLogo = []
					this.ftyLogoList = []
				}
				this.$nextTick(() => {
					// console.log(gh)
					this.ftyLogomodify = gh
				})
			},
			ftyLogoPreview(file) {
				this.ftyLogodialogImageUrl = file.url;
				this.ftyLogodialogVisible = true;
			},
			// 上传前判断类型大小
			beforeUpload(file) {
				console.log(file.type)
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 / 1024 < 3;
				console.log(isJPG)
				console.log(isLt2M)
				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 3MB!');
				}
				return isJPG && isLt2M;
			},
			//百度云上传
			bcebosupload(file, type) {
				var _this = this;
				var date = new Date().getTime(); //时间戳传到百度云与后端作为文件名称
				const config = {
					endpoint: "https://demand.cdn.bcebos.com", //传入Bucket所在区域域名
					credentials: {
						ak: "b8f224aa0b3b41998a3e5be533b05ecb", //您的AccessKey
						sk: "275414e726414fbe90defd870976cc78" //您的SecretAccessKey
					}
				};
				// let bucket = 'zhangzhiedu/upload/mokaostu/video/';
				let bucket = '';
				var key = 'upload/cetificate/' + date + '.' + file.name.split('.')[1];
				let client = new baidubce.sdk.BosClient(config);
				client.putObjectFromBlob(bucket, key, file)
					.then(async response => {
						// 成功
						this.loadings = false
						_this.$message({
							message: '上传成功',
							type: 'success'
						});
						_this.ftyCertificate = _this.ftyCertificate.filter(res => {
							return res != "undefined"
						})
						if (type == 0) {
							_this.ftyCertificate.push({
								name: file.name,
								url: 'https://demand.cdn.bcebos.com/' + key
							})
							if (_this.$route.query.id) {
								_this.newsftyCertificateList.push({
									name: file.name,
									url: 'https://demand.cdn.bcebos.com/' + key
								})
							}
							console.log(_this.ftyCertificate)
							console.log(_this.newsftyCertificateList)
						} else if (type == 1) {
							_this.ftyQualified.push({
								name: file.name,
								url: 'https://demand.cdn.bcebos.com/' + key
							})
							if (_this.$route.query.id) {
								_this.newsftyQualifiedList.push({
									name: file.name,
									url: 'https://demand.cdn.bcebos.com/' + key
								})
							}
						} else if (type == 2) {
							_this.ftyLogo.push({
								name: file.name,
								url: 'https://demand.cdn.bcebos.com/' + key
							})
							if (_this.$route.query.id) {
								_this.newsftyLogoList.push({
									name: file.name,
									url: 'https://demand.cdn.bcebos.com/' + key
								})
							}
						}
					})
					.catch(error => {
						console.error(error)
						_this.$message({
							message: '上传失败',
							type: 'error'
						});
					});
			},
			submit() {
				let _this = this
				let telreg = /[^\d\,]/g
				let wurl = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/
				_this.$refs['examineruleForm'].validate((valid) => {
					if (valid) {
						if (_this.examineruleForm.ftyUrl && !wurl.test(_this.examineruleForm.ftyUrl)) {
							_this.$message({
								message: '公司网址输入不正确',
								type: 'warning'
							});
							return false
						} else {
							let a = []
							let b = []
							let c = []
							console.log(_this.ftyCertificate)
							console.log(_this.newsftyCertificateList)
							console.log(_this.ftyCertificateList)
							if (_this.ftyCertificate && _this.ftyCertificate.length > 0) {
								_this.ftyCertificate.forEach((e, m) => {
									a.push(e.url)
								})
							}
							if (_this.ftyQualified && _this.ftyQualified.length > 0) {
								_this.ftyQualified.forEach((e, m) => {
									b.push(e.url)
								})
							}
							if (_this.ftyLogo && _this.ftyLogo.length > 0) {
								_this.ftyLogo.forEach((e, m) => {
									c.push(e.url)
								})
							}

							if (_this.newsftyCertificateList && _this.newsftyCertificateList.length > 0) {
								_this.newsftyCertificateList.forEach((e, m) => {
									_this.ftyCertificatemodify.forEach((u,i)=>{
										if(u != e.url){
											_this.ftyCertificatemodify.push(e.url)
										}
									})
								})
							}
							if (_this.newsftyQualifiedList && _this.newsftyQualifiedList.length > 0) {
								_this.newsftyQualifiedList.forEach((e, m) => {
									_this.ftyQualifiedmodify.forEach((u,i)=>{
										if(u != e.url){
											_this.ftyQualifiedmodify.push(e.url)
										}
									})
								})
							}
							if (_this.newsftyLogoList && _this.newsftyLogoList.length > 0) {
								_this.newsftyLogoList.forEach((e, m) => {
									_this.ftyLogomodify.forEach((u,i)=>{
										if(u != e.url){
											_this.ftyLogomodify.push(e.url)
										}
									})
								})
							}

							if (_this.ftyCertificateList && _this.ftyCertificateList.length > 0) {
								_this.ftyCertificateList.forEach((k, v) => {
									if (k) {
										a.push(k.url)
									}
								})
							}
							if (_this.ftyQualifiedList && _this.ftyQualifiedList.length > 0) {
								_this.ftyQualifiedList.forEach((k, v) => {
									if (k) {
										b.push(k.url)
									}
								})
							}
							if (_this.ftyLogoList && _this.ftyLogoList.length > 0) {
								_this.ftyLogoList.forEach((k, v) => {
									if (k) {
										c.push(k.url)
									}
								})
							}
							// console.log(_this.ftyCertificate)
							// console.log(a)
							// console.log(a.join(","))
							// console.log(_this.ftyQualified)
							// console.log(b.join(",") + "")
							// console.log(_this.ftyLogo)
							// console.log(c.join(",") + "")
							_this.examineruleForm.ftyFiled3 = _this.userId
							if (_this.$route.query.id) {
								// console.log(_this.ftyCertificatemodify)
								// console.log(Array.from(new Set(_this.ftyCertificatemodify)))
								// console.log(_this.ftyQualifiedmodify)
								// console.log(_this.ftyLogomodify)
								_this.examineruleForm.id = _this.$route.query.id
								if (_this.ftyCertificatemodify && _this.ftyCertificatemodify.length > 0) {
									_this.examineruleForm.ftyCertificate = Array.from(new Set(_this.ftyCertificatemodify)).join(",")
								} else {
									_this.examineruleForm.ftyCertificate = a.join(",")
								}
								if (_this.ftyQualifiedmodify && _this.ftyQualifiedmodify.length > 0) {
									_this.examineruleForm.ftyQualified =Array.from(new Set(_this.ftyQualifiedmodify)).join(",")
								} else {
									_this.examineruleForm.ftyQualified = b.join(",")
								}
								if (_this.ftyLogomodify && _this.ftyLogomodify.length > 0) {
									_this.examineruleForm.ftyLogo = Array.from(new Set(_this.ftyLogomodify)).join(",")
								} else {
									_this.examineruleForm.ftyLogo = c.join(",")
								}
							} else {
								_this.examineruleForm.id = 0
								_this.examineruleForm.ftyCertificate = a.join(",")
								_this.examineruleForm.ftyQualified = b.join(",")
								_this.examineruleForm.ftyLogo = c.join(",")
							}
							// console.log(_this.examineruleForm)
							// for (var key in _this.examineruleForm) {
							// 	if (!_this.examineruleForm[key]) {
							// 		_this.examineruleForm[key] = 'null'
							// 	}
							// }
							_this.$post("programme/addFactoryInfo", _this.examineruleForm).then((res) => {
								_this.$message({
									message: _this.$route.query.id ? '修改成功' : '添加成功',
									type: 'success'
								});
								_this.$router.push({
									path: "manufactorlist"
								})
							})
						}
					} else {
						_this.$message({
							message: '信息不完整',
							type: 'error'
						});
						return false;
					}
				});
			}
		}
	}
</script>

<style scoped lang="less">
	.formbody {
		.buttons {
			margin: 60px 0 20px 0;
			text-align: center;

			>:first-child {
				margin-right: 100px;
			}
		}

		.el-input {
			width: 40%;
		}
	}
</style>
